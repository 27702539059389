import { Navigate, Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import '@fontsource/roboto/latin.css';

import Dashboard from './pages/Dashboard';
import DownloadFlowrate from './pages/DownloadFlowrate';
import Login from './pages/Login';
import Reports from './pages/Reports';
import UploadFlowrate from './pages/UploadFlowrate';
import UploadFileRetry from './pages/UploadFileRetry';

import { AuthProvider } from './context/Auth';

import AuthWrapper from './components/AuthWrapper';
import Snack from './components/Snack';

const theme = createTheme();

function App() {
  return (
    <>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes>
            <Route element={<AuthWrapper/>}>
              <Route path="/" element={<Navigate to="/dashboard"/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/download-files" element={<DownloadFlowrate/>} />
              <Route path="/upload-files" element={<UploadFlowrate/>} />
              <Route path="/reports">
                <Route index element={<Reports />} />
                <Route path=":serialCode" element={<Reports />} />
                <Route path=":serialCode/:reportId" element={<UploadFileRetry/>} />
              </Route>
            </Route>
            <Route path="/login" element={<Login/>} />
          </Routes>
        </AuthProvider>
        <Snack variant="error" />
        <Snack variant="warning" />
        <Snack variant="success" />
      </ThemeProvider>
    </>
  );
}

export default App;
