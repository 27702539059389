import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function CenterLoader() {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{height:'100%'}}>
      <Grid item>
        <CircularProgress/>
      </Grid>
    </Grid>
  );
}

export default CenterLoader;
