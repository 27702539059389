export default function (array, match) {
  const idx = typeof match === 'function'
    ? array.findIndex(match)
    : array.indexOf(match);

  if(idx === -1) return array;

  const clone = [ ...array ];
  clone.splice(idx, 1);
  return clone;
}
