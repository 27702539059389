import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { differenceInSeconds, formatDuration } from 'date-fns';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import UploadIcon from '@mui/icons-material/CloudUpload';

import { FolderUpload } from '../components/FileUpload';
import { snack } from '../components/Snack';
import StyledForm from '../components/StyledForm';

import { apiPost } from '../utils/backend-api';
import uploadProvider from '../utils/uploader';

const validationSchema = {
  afc_serial:      Yup.string().required(),
  meter_no:        Yup.string().required(),
  test_type:       Yup.string().required(),
  medium:          Yup.string().required(),
  user_id:         Yup.string().required(),
  location_id:     Yup.string().required(),
  inlet_pressure:  Yup.number().required(),
  outlet_pressure: Yup.number().required(),
  housing_version: Yup.string().required(),
  mic_version:     Yup.string().required(),
};

function UploadFlowrate() {
  const [files, setFiles] = useState();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [estimateTimeLeft, setEstimatedTimeLeft] = useState();

  const navigate = useNavigate();

  const handleFolderUpload = ({ files:selectedFiles, dragAndDrop }) => {
    setFiles(selectedFiles);
    setIsDragAndDrop(dragAndDrop);
  };

  const calculateEstimatedFinish = (start, done, total) => {
    const elapsed = differenceInSeconds(new Date(), start);
    const estimateMinutes = Math.floor(((total * elapsed) / done) / 60); // convert to minutes
    setEstimatedTimeLeft(estimateMinutes);
  };

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    if(!files || !files.length) {
      throw new Error('No folder selected');
    }

    const uploader = uploadProvider();

    setUploading(true);
    setSubmitting(true);

    const timeStarted = new Date();

    const batchSize = 100;
    const totalBatches = Math.round(files.length / batchSize);
    let batchIndex = 0;
    const slice = (arr, idx) => arr.slice(idx * batchSize, (idx + 1) * batchSize);

    await uploadBatch();

    async function uploadBatch() {
      const batch = isDragAndDrop ? slice(files, batchIndex) : slice(Array.from(files), batchIndex);

      if(batch.length === 0) {
        const processedFiles = files.length;
        setProgress(0);
        setUploading(false);
        resetForm();
        setFiles(null);
        snack.success(`${files.length} files uploaded successfully.`);
        navigate(`/reports/${values.afc_serial}`, {
          state: {
            timeStarted,
            timeCompleted: new Date(),
            processedFiles,
          },
        });
        return;
      }

      if(!uploading) {
        setUploading(true);
      }

      const jsonFiles = batch.filter(file => file.type === 'application/json');

      try {
        await uploader(batch, values.afc_serial);

        await processJsonBatch(jsonFiles);
        batchIndex++;
        setProgress(Math.floor((batchIndex * batchSize * 100) / files.length));
        calculateEstimatedFinish(timeStarted, batchIndex, totalBatches);
        await uploadBatch();
      } catch(err) {
        setUploading(false);
        setProgress(0);
        snack.error(`Upload failed. ${err.message}`);
      }
    }

    async function processJsonBatch(_files) {
      try {
        const promisedContents = _files
          .map(file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsText(file);
          }));

        const contents = await Promise.all(promisedContents);

        // TODO this request should always return 200 unless it's a network issue
        await apiPost('flow-rate/store-json-data', { ...values, afc_json_data:contents });
      } catch(err) {
        snack.error(`Error: ${err.message}`);
      }
    }
  };

  return (
    <Container style={{ paddingTop:30 }}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Typography variant="h4">AFC Upload</Typography>
        </Grid>
        <Grid item style={{ width:'100%' }}>
          <StyledForm
            submitText="upload"
            onSubmit={onSubmit}
            schema={validationSchema}
            fields={[
              <InputFieldGrid
                title="PayGo AFC Test"
                fields={[
                  <Field component={TextField} name="afc_serial"  label="AFC serial" required />,
                  <Field component={TextField} name="meter_no"    label="Meter No."  required />,
                  <Field component={TextField} name="test_type"   label="Test Type"  required />,
                  <Field component={TextField} name="medium"      label="Medium"     required />,
                  <Field component={TextField} name="user_id"     label="User ID"    required />,
                  <Field component={TextField} name="location_id" label="Location"   required />,
                  <FolderUpload
                    name="upload_files"
                    uploading={uploading}
                    folder={files}
                    label="Select the folder on the SD card"
                    handleFolderUpload={handleFolderUpload}
                  />,
                ]}
              />,
              <InputFieldGrid
                title="AFC Test Parameter"
                fields={[
                  <Field component={TextField} name="inlet_pressure"  label="Inlet Pressure(Bar)"   required />,
                  <Field component={TextField} name="outlet_pressure" label="Outlet Pressure(mBar)" required />,
                  <Field component={TextField} name="housing_version" label="Housing Version"       required />,
                  <Field component={TextField} name="mic_version"     label="Mic Version"           required />,
                ]}
              />,
              <Box sx={{ display:'flex', justifyContent:'flex-end' }}>
                <Paper hidden={!uploading} elevation={3} style={{ width:'100%', padding:10 }}>
                  <Box sx={{ display:'flex', alignItems:'center' }}>
                    <Box sx={{ width:'100%', mr:1 }}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ minWidth:35 }}>
                      <Typography variant="body2" color="text.secondary">{`${progress}%`}</Typography>
                    </Box>
                    <Box sx={{ ml:1 }}>
                      <UploadIcon fontSize="large" color="primary" />
                    </Box>
                  </Box>
                  <Box sx={{ display:'flex', justifyContent:'flex-end' }}>
                    <Typography variant="subtitle2" color="text.secondary">{formatEstimatedTimeLeft(estimateTimeLeft)}</Typography>
                  </Box>
                </Paper>
              </Box>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default UploadFlowrate;

const InputFieldGrid = ({ fields, title }) => (
  <Grid container item direction="column" spacing={2} alignItems="stretch">
    <Grid container item direction="column" alignItems="center"><Typography variant="h4">{title}</Typography></Grid>
    {fields.map(f => <Grid item key={f.props.name}>{f}</Grid>)}
  </Grid>
);

const formatEstimatedTimeLeft = estimate => {
  if(estimate) {
    const duration = {};
    duration.hours = Math.floor(estimate / 60);
    duration.minutes = estimate % 60;

    return `${formatDuration(duration)} left`;
  }
  return 'calculating time left...';
};
