import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default ({ defaultValue, label, onSubmit, placeholder }) => {
  const [ value, setValue ] = useState(defaultValue ?? '');

  const onSearch = e => { e.preventDefault(); onSubmit(value||''); };

  const searchButton = <InputAdornment position="end"><IconButton onClick={onSearch}><SearchIcon/></IconButton></InputAdornment>;

  return (
    <form onSubmit={onSearch}>
      <FormControl>
        {label && <InputLabel>{label}</InputLabel>}
        <Input type="text" endAdornment={searchButton} onChange={e => setValue(e.target.value)} value={value} placeholder={placeholder}/>
      </FormControl>
    </form>
  );
};
