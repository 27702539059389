import { format } from 'date-fns';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

import config from '../config';
import firebase from './firebase-app';
import getUnixTimestampFromFileName from './get-unix-timestamp-from';
import { apiPost } from './backend-api';

const RETRIES = 3;

export default function uploader() {
  let retryCount = 0;
  const { gcsBucketName } = config;

  return handleUploads;

  async function handleUploads(files, serialCode) {
    const directories = new Set();

    const res = await Promise.allSettled(files
      .map(file => new Promise((resolve, reject) => {
        if(!validFileType(file.type)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ err:new Error('Unsupported file type'), retry:false });
          return;
        }

        const unixTimestamp = getUnixTimestampFromFileName(file.name);
        const date = format(new Date(unixTimestamp * 1000), 'yyyy-MM-dd');

        const filename = `${serialCode}/${date}/${file.name}`;
        const storage = getStorage(firebase.app, gcsBucketName);
        const storageRef = ref(storage, filename);
        const metadata = { name:filename, contentType:file.type, size:file.size };
        uploadBytes(storageRef, file, metadata)
          .then(() => {
            directories.add(date);
            resolve();
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          .catch(err => reject({ err, file, retry:true }));
      })));

    const failed = res
      .filter(r => r.status === 'rejected')
      .filter(r => r.reason.retry)
      .map(promise => promise.reason.file);

    if(!failed.length) {
      return new Promise(resolve => {
        // We don't expect error at this point, we assume "happy" path
        apiPost('uploads/copy-folders-to-s3', { directories:[...directories], serial_code:serialCode })
          .then(resolve)
          .catch(err => resolve(err));
      });
    }

    if(failed.length && retryCount < RETRIES) {
      retryCount++;
      return handleUploads(failed, serialCode);
    }

    if(failed.length && retryCount === RETRIES) {
      const failed_file_names = failed.map(f => f.name);
      return new Promise(resolve => {
        apiPost(`uploads/report-failed?serial_code=${serialCode}`, { failed_file_names })
          // Ideally this shouldn't fail but if a user doesn't have a good internet
          // connection then this request to the backend will fail
          .catch(err => resolve(err));
      });
    }
  }
}

function validFileType(type) {
  if(type !== 'application/json' && !/audio\/[-+.\w]+/.test(type)) {
    return false;
  }

  return true;
}
