import BackendRequest from './backend-request';

const backendRequest = new BackendRequest();
const streamRequest = new BackendRequest({
  isBlob: true,
  cache: 'reload',
  headers: {
    Accept: 'application/octet-stream',
  },
});

export const apiGet = backendRequest.get;
export const apiPost = backendRequest.post;

export const apiGetStream = streamRequest.get;
