import { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import DownloadIcon from '@mui/icons-material/Download';
import ReportsIcon from '@mui/icons-material/Description';
import UploadIcon from '@mui/icons-material/DriveFolderUpload';

import Header from './Header';

import { AuthContext } from '../context/Auth';
import { apiGet } from '../utils/backend-api';

function AuthWrapper() {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    apiGet('authd');
  }, []);

  if(!user && !loading) {
    return <Navigate to="/login" />;
  }

  return (
    <Header menuItems={[
      { text:'Dashboard',      linkTo:'/dashboard',      icon:<DashboardIcon/> },
      { text:'Download Files', linkTo:'/download-files', icon:<DownloadIcon/> },
      { text:'Upload Files',   linkTo:'/upload-files',   icon:<UploadIcon/> },
      { text:'Reports',        linkTo:'/reports',        icon:<ReportsIcon/> },
    ]}>
      <Outlet />
    </Header>
  );
}

export default AuthWrapper;
