import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';

import { snack } from './Snack';

import { apiPost } from '../utils/backend-api';

function ReportAction({ report, type, onRetryClick, onCancelClick, onPostSuccess }) {
  const { serialCode:serial_code } = useParams();
  const [loading, setLoading] = useState(false);

  const handleCancelClick = () => {
    setLoading(true);
    if(onCancelClick) {
      onCancelClick();
      return;
    }

    apiPost('uploads/mark-report-resolved', { report_id:report.id, report_type:type, serial_code })
      .then(() => {
        snack.success('Report marked as resolved.');
        onPostSuccess();
      })
      .catch(err => snack.error(`Error: Could not mark report as resolved. ${err.message}`))
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ display:'flex', justifyContent:'flex-end' }}>
      <CardActions>
        <Button disabled={loading} onClick={handleCancelClick} color="error" size="small">Cancel</Button>
        <Button disabled={loading} onClick={() => onRetryClick({ ...report, serial_code })} variant="contained" size="small">Retry</Button>
      </CardActions>
    </Box>
  );
}

export default ReportAction;
