import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import JsonIcon from '@mui/icons-material/DataObject';
import Typography from '@mui/material/Typography';
import WavIcon from '@mui/icons-material/VolumeUp';

import ReportAction from './ReportAction';

const icons = {
  json: JsonIcon,
  wav: WavIcon,
};

function ReportUploadInfo({ data, onPostSuccess }) {
  const navigate = useNavigate();

  const onRetryClick = report => {
    navigate(`/reports/${report.serial_code}/${report.id}`, { state:report });
  };

  return (
    <>
      {data.map((info, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={`${i}-upload-${info.resolved}`} xs={12} sm={8} md={6} lg={4} item>
          <Card sx={{ ':hover':{ boxShadow:20 }}}>
            <CardContent>
              {info.files.map(name => <FileNameContent key={name} name={name} />)}
            </CardContent>
            <ReportAction
              type="uploads"
              onPostSuccess={onPostSuccess}
              report={info}
              onRetryClick={onRetryClick}
            />
          </Card>
        </Grid>
      ))}
    </>
  );
}

export default ReportUploadInfo;

const isJson = name => /json/.test(name);

const FileNameContent = ({ name }) => (
  <>
    <Icon variant={isJson(name) ? 'json' : 'wav'} />
    <Typography variant="body2">{name}</Typography>
  </>
);

const Icon = ({ variant, ...props }) => {
  const Variant = icons[variant];
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Variant {...props} />;
};
