import { useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const useStyles = makeStyles(({ breakpoints, mixins, zIndex }) => ({
  appBar:      { [breakpoints.up('md')]:{ zIndex:`${zIndex.drawer+1} !important` } },
  accountMenu: { [breakpoints.up('md')]:{ zIndex:`${zIndex.drawer+1} !important` } },
  logo: { height:'2em', marginRight:'1em' },
  headerBackground: { backgroundColor:'#00254a', color:'white' },
  grow: { flexGrow:1 },
  menuButton: { [breakpoints.up('md')]:{ display:'none !important' }, marginLeft:-12, marginRight:20 },
  largeToolbar: mixins.toolbar,
  largeDrawer: { width:drawerWidth, zIndex:`${zIndex.drawer} !important` },
  permanentContent: { [breakpoints.up('md')]:{ marginLeft:drawerWidth } },
}));

function Header(props) {
  const { children, menuItems } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.headerBackground}>
          <IconButton onClick={() => setDrawerOpen(prev => !prev)} className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Link to="/"><img alt="PayGo Energy" src="/assets/logo-menubar.svg" className={classes.logo}/></Link>
        </Toolbar>
      </AppBar>
      <>
        <Hidden smDown>
          <Drawer variant="permanent" className={classes.largeDrawer} classes={{ paper:classes.largeDrawer }}>
            <div className={classes.largeToolbar}/>
            <List>
              {menuItems.map(ListMenuItem)}
            </List>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <List>
              {menuItems.map(ListMenuItem)}
            </List>
          </Drawer>
        </Hidden>
      </>
      <main className={classes.permanentContent}>{children}</main>
    </>
  );
}

export default Header;

const ListMenuItem = ({ linkTo, icon, text }) => {
  const selected = window.location.pathname.startsWith(linkTo);

  return (
    <ListItem selected={selected} button component={Link} to={linkTo} key={linkTo}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
