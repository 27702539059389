import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { withStyles } from '@mui/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

// TODO fix the theming/stylng it doesn't really work for SnackbarContent
const styles = theme => ({
  success: { backgroundColor:green[600] },
  error: { backgroundColor:theme.palette.error.dark },
  info: { backgroundColor:theme.palette.primary.dark },
  warning: { backgroundColor:amber[700] },
  icon: { fontSize:20 },
  iconVariant: { opacity:0.9, marginRight:theme.spacing(1) },
  message: { display:'flex', alignItems:'center' },
});

export const snack = {};

class Snack extends Component {
  state = {};
  constructor(props) {
    super(props);
    snack[this.props.variant] = this.show;
  }

  show = message => this.setState({ message });

  close = () => this.setState({ message:null });

  render() {
    const { classes, variant } = this.props;
    const { message } = this.state;

    if(!message) {
      return null;
    }

    const Icon = variantIcon[variant];

    const content =
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>;

    const closeButton =
        <IconButton key="close" aria-label="Close" color="inherit"
            className={classes.close} onClick={this.close}>
          <CloseIcon className={classes.icon} />
        </IconButton>;

    return (
      <Snackbar open onClose={this.close} autoHideDuration={6000}>
        <SnackbarContent
            className={classNames(classes[variant])}
            aria-describedby="client-snackbar"
            message={content} action={[ closeButton ]}/>
      </Snackbar>
    );
  }
}

Snack.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default withStyles(styles)(Snack);
