import { getUnixTime } from 'date-fns';

export default function getUnixTimestampFromFileName(filename) {
  /**
    * here we assume the audio's file name would always be in the following format
    * UNIX_TIMESTAMP_audio_flow_none_SERIAL_NUMBER[21.19].wav
    * e.g 1684421108_audio_flow_none_SERIAL_001[21.9].wav
    * perhaps we should use the current upload time cast to milliseconds
    */
  const matched = RegExp(/(\d{9,})_/, 'g').exec(filename);

  if(matched === null) {
    const currentUploadTimestamp = getUnixTime(new Date());
    return currentUploadTimestamp;
  }

  return parseInt(matched[1], 10);
}
