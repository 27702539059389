import { format, addDays } from 'date-fns';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { snack } from '../components/Snack';
import StyledForm from '../components/StyledForm';

import { apiGetStream } from '../utils/backend-api';

function DownloadFlowrate() {
  return (
    <Container style={{ paddingTop:30 }}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Typography variant="h4">AFC Download</Typography>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledForm
              onSubmit={values => {
                const serialCode = values.serial_code;
                const startTimestamp = new Date(values.start_date).valueOf(); // TODO consider passing the date as is
                const endTimestamp = values.end_date && new Date(values.end_date).valueOf();
                let uriPath = `flow-rate/json-and-audio-files/${serialCode}?start_time=${startTimestamp}`;
                if(values.end_date) {
                  uriPath += `&end_time=${endTimestamp}`;
                }

                apiGetStream(uriPath)
                  .then(res => res.blob())
                  .then(data => {
                    const downloader = document.createElement('a');
                    downloader.href = window.URL.createObjectURL(data);
                    const humanReadable = timestamp => format(timestamp, 'yyyy-MM-dd');
                    let downloadFilename =`${serialCode}_${humanReadable(startTimestamp)}_`;

                    if(!endTimestamp) {
                      downloadFilename += `${humanReadable(endTimestamp)}.tar.gz`;
                    } else {
                      downloadFilename += `${humanReadable(addDays(startTimestamp, 7))}.tar.gz`;
                    }

                    downloader.download = downloadFilename;
                    downloader.click();
                  })
                  .catch(err => snack.error(`Error: ${err.message}`));
              }}
              submitText="download"
              schema={{
                serial_code: Yup.string().required(),
                start_date:  Yup.string().required(),
                end_date:    Yup.string(),
              }}
              fields={[
                <Field component={TextField} name="serial_code" label="AFC serial" required />,
                <Field component={DateField} name="start_date"  label="Start Date" required format="dd/MM/yyyy" />,
                <Field component={DateField} name="end_date"    label="End Date" format="dd/MM/yyyy" />,
              ]}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DownloadFlowrate;

const DateField = props => {
  const { field:{ name }, form:{ errors, setFieldValue } } = props;

  return (
    <>
      <FormControl required={props.required} error={Boolean(errors[name])}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <DatePicker {...props} onChange={val => setFieldValue(name, val)} />
        {errors[name] && <FormHelperText error>{errors[name]}</FormHelperText> }
      </FormControl>
    </>
  );
};
