import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { snack } from './Snack';

export default (props) => {
  const { fields, schema, submitText, onSubmit } = props;
  const validationSchema = Yup.object().shape(schema);

  const initialValues = props.initialValues || initialValuesFrom(validationSchema);

  const __onSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      await onSubmit(values, { resetForm, setSubmitting });
    } catch(err) {
      snack.error(`Error: ${err.message}`);
    }
  };

  return (
    <Container style={{ padding:20 }}>
      <Card>
        <CardContent>
          <Formik onSubmit={__onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ handleSubmit }) => (
              <Form>
                <Grid container item spacing={2} direction="column" alignItems="stretch">
                  {fields
                    .map((field, i) => <Grid item key={field.props?.name || i}>{field}</Grid>)}
                  <Grid container item justifyContent="center">
                    <Button onClick={handleSubmit} variant="outlined">{submitText}</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
};

const initialValuesFrom = schema => {
  const initial = schema.default();
  Object
      .keys(initial)
      .forEach(k => {
        initial[k] = getDefaultValueFor(schema.fields[k]);
      });
  return initial;
};

function getDefaultValueFor(field) {
  // TODO we could do a check for the field.getDefault() response here, but we
  // don't currently use it, and would require some testing around objects, e.g.
  // geolocation fields currently default to "" instead of { lat, lng }

  if(field._nullable) {
    return null;
  }

  switch(field.type) {
    case 'array':   return [];
    case 'boolean': return false;
    case 'date':    return new Date();
    default:        return '';
  }
}
