import { apiGet } from './utils/backend-api';

const config = {};

export default config;

let loader;
export const initConfig = () => {
  if(!loader) {
    loader = apiGet('runtime-env')
      .then(runtimeEnv => {
        if(!runtimeEnv) {
          // non-fatal error, hopefully handled elsewhere (e.g. redirect to login)
          return;
        }
        Object.keys(runtimeEnv)
          .forEach(prop => {
            config[prop] = runtimeEnv[prop];
          });
      });
  }
  return loader;
};
