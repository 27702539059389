import { useContext, useState } from 'react';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import _pick from 'lodash/pick';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../context/Auth';
import BackendRequest from '../utils/backend-request';

const backendRequest = new BackendRequest();

const styles = {
  page: {
    backgroundColor: '#00254A',
    height: '100vh',
    overflow: 'hidden',
  },
  logo: {
    width: '100vh',
    maxWidth: '300px',
  },
  btnIcon: {
    height: '2em',
  },
  error:    { textAlign:'center' },
  errorSub: { textAlign:'center' },
};

function Login() {
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const provider = new GoogleAuthProvider();

  const handleLogin = async () => {
    const auth = getAuth();

    setLoading(true);
    try {
      const { user } = await signInWithPopup(auth, provider);

      const { accessToken:id_token, email} = user;
      const body = { id_token, email };

      await backendRequest.post('login', body);

      login({ name:user.displayName, ..._pick(user, ['email', 'photoURL']) });
    } catch(err) {
      // show toast message
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container style={styles.page} alignItems="center">
      <Grid container direction="column" alignItems="center" style={{color:'white'}}>
        <Grid item xs>
          <Typography variant="h3">Resonance</Typography>
        </Grid>
        <Grid item xs>
          <Button
            startIcon={<Icon><img style={{marginBottom:5}} src="/assets/google-signin-button.svg" alt=""/></Icon>}
            disabled={loading}
            variant="contained"
            style={{backgroundColor:'#e0e0e0', color:'black'}}
            onClick={handleLogin}
          >
            Sign in With Google
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;
