import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { apiPost } from '../utils/backend-api';

import ReportAction from './ReportAction';
import { snack } from './Snack';

function ReportRequestInfo({ data, onPostSuccess }) {
  const onRetryClick = report => {
    const {
      id: report_id,
      body: request_body,
      coresvcPath: coresvc_path,
      serial_code,
    } = report;

    apiPost('uploads/retry-coresvc-request', { report_id, request_body, coresvc_path, serial_code })
      .then(res => snack.success(res.message))
      .catch(err => snack.error(`Error: ${err.message}. Could not retry request.`));
  };

  return (
    <>
      {data.map((info, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={`${i}-request-${info.resolved}`} xs={12} sm={8} md={6} lg={4} item>
          <Card sx={{ ':hover':{ boxShadow:20 }}}>
            <CardContent>
              <RequestInfoTable content={[
                  [ 'Error', info.err ],
                  [ 'Error Description', info.failure ],
                  [ 'Destination Path', info.coresvcPath ],
                  [ 'Request Body', <code><pre style={{whiteSpace:'pre-wrap'}}>{JSON.stringify(info.body)}</pre></code> ],
                ]}
              />
            </CardContent>
            <ReportAction
              type="coresvc_request"
              onPostSuccess={onPostSuccess}
              report={info}
              onRetryClick={onRetryClick}
            />
          </Card>
        </Grid>
      ))}
    </>
  );
}

export default ReportRequestInfo;

const RequestInfoTable = ({ content }) => {
  const maxLength = Math.max(...content.map(row => row.length));

  return (
    <Table>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {content.map((row, i) => <InfoRow key={`info-${i}-${row[0]}`} content={row} len={maxLength}/>)}
    </Table>
  );
};

function InfoRow({ content, len }) {
  const cells = [...content];
  while(cells.length < len) cells.push(null);

  return (
    <TableBody>
      <TableRow>
        {/* eslint-disable-next-line react/no-array-index-key */}
        {cells.map((c, i) => <TableCell key={`cell-${i}`}>{c}</TableCell>)}
      </TableRow>
    </TableBody>
  );
}
