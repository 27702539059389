import { useEffect } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { apiGet } from '../utils/backend-api';

import Pkg from '../../package.json';

function Dashboard() {
  useEffect(() => {
    apiGet('authd');
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={8} style={{padding:20, margin:0, width:'100%'}}>
      <Grid item xs={12} sm={8} md={6}>
        <Dashcard>
          Welcome to Resonance
        </Dashcard>
        <Dashcard>
          <List dense>
            <ListItem><ListItemText primary="AFC Server" secondary="resonance" /></ListItem>
            <ListItem><ListItemText primary="Running version" secondary={Pkg.version} /></ListItem>
          </List>
        </Dashcard>
      </Grid>
    </Grid>
  );
}

export default Dashboard;

const Dashcard = ({ children }) => (
  <Card>
    <CardContent>
      <Typography variant="h2" align="center">
        {children}
      </Typography>
    </CardContent>
  </Card>
);
