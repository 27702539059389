import { initializeApp } from 'firebase/app';
import config from '../config';

const firebase = {};
export default firebase;

export function initialiseFirebase() {
  if(!firebase.app) {
    firebase.app = initializeApp(config.firebaseConfig);
  }
  return firebase;
}
