import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import JsonIcon from '@mui/icons-material/DataObject';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import SelectedIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import UnselectedIcon from '@mui/icons-material/DoNotDisturbOn';
import WavIcon from '@mui/icons-material/VolumeUp';

import { FileInputFab, FolderDropZone } from '../components/FileUpload';
import { snack } from '../components/Snack';

import BackendRequest from '../utils/backend-request';
import config from '../config';
import firebase from '../utils/firebase-app';
import getUnixTimestampFromFileName from '../utils/get-unix-timestamp-from';
import removeFromArray from '../utils/remove-from-array';

const backendRequest = new BackendRequest();

function UploadFileRetry() {
  const { gcsBucketName } = config;

  const[files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const handleFileChange = ({ files:selectedFiles }) => {
    setFiles(prev => ([ ...prev, ...selectedFiles ]));
  };

  const handleUploadClick = async () => {
    const { id:report_id, serial_code } = state;

    setUploading(true);
    try {
      await Promise.all(files
        .map(file => new Promise((resolve, reject) => {
          const unixTimestamp = getUnixTimestampFromFileName(file.name);
          const date = format(new Date(unixTimestamp * 1000), 'yyyy-MM-dd');

          const filename = `${state.serial_code}/${date}/${file.name}`;
          const storage = getStorage(firebase.app, gcsBucketName);
          const storageRef = ref(storage, filename);
          const metadata = { name:filename, contentType:file.type, size:file.size };
          uploadBytes(storageRef, file, metadata)
            .then(resolve)
            .catch(err => reject(err));
        })));

      await backendRequest
        .post('uploads/mark-report-resolved', { report_id, report_type:'uploads', serial_code });

      setFiles([]);

      navigate('/reports');
      snack.success('Upload successful.');
    } catch(err) {
      snack.error(`Error: ${err.message}`);
    } finally {
      setUploading(false);
    }
  };

  if(!state?.serial_code) {
    return <Navigate to="/reports" />;
  }

  return (
    <Container style={{ paddingTop:30 }}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Typography variant="h4">Retry Upload for {state.serial_code}</Typography>
          <Typography variant="subtitle1">Total files to upload: {state.files.length}</Typography>
        </Grid>
      </Grid>
      <Grid item style={{ width:'100%' }}>
        <Paper>
          <Grid container direction="column" alignItems="center" style={{ padding:8 }}>
            <Grid container direction="column" item>
              <Grid item>
                <Typography variant="h6">Expected files</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  {state.files.map(f => (
                    <ListItem key={`expected-${f}`}>
                      <ListItemIcon><FileIcon type={f.match(/json|wav/)[0]} /></ListItemIcon>
                      <ListItemText primary={f} />
                      <ListItemSecondaryAction>
                        <FileSelectedBadge fileName={f} selected={files} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid container direction="column" item>
              <Grid item>
                <Typography variant="h6">Select files</Typography>
              </Grid>
              <Grid item>
                <FolderDropZone>
                  <List dense>
                    {files.map(file => (
                      <ListItem key={file.name}>
                        <ListItemIcon><FileIcon type={file.type} /></ListItemIcon>
                        <ListItemText primary={file.name} />
                        <ListItemSecondaryAction onClick={() => setFiles(removeFromArray(files, file))}>
                          <IconButton edge="end" aria-label="delete"><DeleteIcon /></IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <FileInputFab allowSingeFiles="true" onChange={handleFileChange} />
                </FolderDropZone>
              </Grid>
              <Grid container item justifyContent="center">
                <Button disabled={!files.length || uploading} onClick={handleUploadClick} variant="outlined">Upload</Button>
                {uploading &&
                  <Backdrop
                    sx={{ color:'#a2cffc', zIndex:theme => theme.zIndex.drawer + 1 }}
                    open={uploading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                }
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}

export default UploadFileRetry;

const FileIcon = ({ type }) => {
  if(!type) return <InsertDriveFile />;
  if(/json/.test(type)) return <JsonIcon />;
  if(/wav/.test(type))  return <WavIcon />;
  return <InsertDriveFile />;
};

const FileSelectedBadge = ({ fileName, selected }) => {
  const fileSelected = selected.some(f => f.name === fileName);

  if(fileSelected) {
    return <SelectedIcon color="success" />;
  }

  return <UnselectedIcon color="disabled" />;
};
