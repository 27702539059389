import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _startCase from 'lodash/startCase';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration,
} from 'date-fns';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import StatusOkayIcon from '@mui/icons-material/CheckCircle';
import StatusNotOkayIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';

import CenterLoader from '../components/CenterLoader';
import ReportUploadInfo from '../components/ReportUploadInfo';
import ReportRequestInfo from '../components/ReportRequestInfo';
import SearchTextField from '../components/SearchTextField';

import { apiGet } from '../utils/backend-api';

function Reports() {
  const params = useParams();
  const navigate = useNavigate();
  const { state:navigationState } = useLocation();
  const [state, setState] = useState({ serial:params.serialCode, loading:true });

  const fetchReports = useCallback(async () => {
    if(state.serial) {
      try {
        setState(prev => ({ ...prev, loading:true }));
        const reports = await apiGet(`uploads/${state.serial}`);
        setState(prev => ({ ...prev, reports, loading:false }));
      } catch(err) {
        setState(prev => ({ ...prev, reports:{ error:err.toString() }, loading:false }));
      }
    }
  }, [state.serial]);

  const searchAfcSerial = async afcSerial => {
    const serial = afcSerial.trim();
    navigate(`/reports/${serial}`);
    setState({ serial });
    await fetchReports();
  };

  const onPostSuccess = () => fetchReports();

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const { reports } = state;

  return (
    <Grid container justifyContent="center" style={{ padding:10 }}>
      <Grid item xs={12} sm={10} md={8} style={{ width:'100%', margin:10, padding:20 }}>
        <Card>
          <CardContent align="right">
            <SearchTextField label="Search AFC Serial" defaultValue={state.serial ?? ''} onSubmit={searchAfcSerial} />
          </CardContent>
        </Card>
      </Grid>
      {state.serial && state.loading && <CenterLoader />}
      {navigationState?.timeStarted &&
        <ReportStatus
          timeStarted={navigationState.timeStarted}
          timeCompleted={navigationState.timeCompleted}
          processedFiles={navigationState.processedFiles}
          status={reports?.status}
          loading={state.loading}
        />}
      {reports?.uploads && Object.keys(reports.uploads).length &&
        <ReportGrid
          title="file uploads"
          data={reports.uploads}
          renderInfo={data => Object.keys(data)
            .map(k => (
              <Grid container spacing={2} item key={k}>
                <Grid item xs={12}>
                  <Typography variant="h6">{k}</Typography>
                  <Typography variant="subtitle1">({data[k].length} reports)</Typography>
                </Grid>
                <ReportUploadInfo onPostSuccess={onPostSuccess} data={data[k]} />
              </Grid>
            ))}
        />}
      {reports?.requests && Object.keys(reports.requests).length &&
        <ReportGrid
          title="json data post"
          data={reports.requests}
          renderInfo={data => Object.keys(data)
            .map(k => (
              <Grid container spacing={2} item key={k}>
                <Grid item xs={12}>
                  <Typography variant="h6">{k}</Typography>
                  <Typography variant="subtitle1">({data[k].length} reports)</Typography>
                </Grid>
                <ReportRequestInfo onPostSuccess={onPostSuccess} data={data[k]} />
              </Grid>
            ))}
        />}
    </Grid>
  );
}

export default Reports;

function ReportGrid({ title, data, renderInfo }) {
  return (
    <Grid container item marginTop={2}>
      <Typography variant="h4">{_startCase(title)}</Typography>
      {renderInfo(data)}
    </Grid>
  );
}

function ReportStatus({ timeStarted, timeCompleted, processedFiles, loading, ...props }) {
  const status = props.status ?? 'okay'; // assume everything is okay unless the server actually says otherwise
  const timesince = getTimeTaken(timeCompleted);
  const timetaken = getTimeTaken(timeStarted, timeCompleted, true);

  if(loading) {
    return (
      <Grid container item>
        <Card>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" sx={{ fontSize:'1.5rem' }} />
              <Skeleton variant="text" sx={{ fontSize:'1rem' }} />
              <Skeleton variant="text" sx={{ fontSize:'1rem' }} />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid container item>
      <Card>
        <CardContent>
          <StatusIcon status={status} />
          <Typography variant="body1">{processedFiles} file(s) processed</Typography>
          <Typography variant="subtitle2" color="text.secondary">Upload took {timetaken}</Typography>
          <Typography variant="subtitle2" color="text.secondary">Uploaded completed {timesince ?? 'a few seconds'} ago</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

const getTimeTaken = (start, end=new Date(), showSeconds=false) => formatDuration({
  hours: differenceInHours(end, start),
  minutes: differenceInMinutes(end, start),
  seconds: showSeconds && differenceInSeconds(end, start) % 60,
});

function StatusIcon({ status }) {
  const Icon = status === 'okay' ? StatusOkayIcon : StatusNotOkayIcon;
  const color = status === 'okay' ? 'success' : 'warning';

  return <Icon color={color} />;
}
