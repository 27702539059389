import { useRef, useState } from 'react';
import DropZone from 'react-dropzone';

import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import FilesIcon from '@mui/icons-material/FileCopy';
import FolderIcon from '@mui/icons-material/Folder';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const FolderUpload = ({ label, folder, handleFolderUpload, uploading }) => (
  <>
    <FormLabel component="legend">{label}</FormLabel>
    <FolderDropZone onFolderDrop={handleFolderUpload}>
      {folder &&
        <List dense>
          <ListItem>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary={`Root Folder: ${formatFolderPath(folder)}`} />
          </ListItem>
          <ListItem>
            <ListItemIcon><FilesIcon /></ListItemIcon>
            <ListItemText primary={`${folder.length} files`} />
          </ListItem>
        </List>
      }
      <FileInputFab disabled={uploading} onChange={handleFolderUpload}/>
    </FolderDropZone>
  </>
);

const formatFolderPath = files => {
  const rootPath = filePath => filePath
    .split('/')
    .filter(it => it)[0];
  if(files[0].webkitRelativePath.length) {
    return rootPath(files[0].webkitRelativePath);
  }
  return rootPath(files[0].path);
};

export const FolderDropZone = (props) => {
  const [uploading, setUploading] = useState(false);

  const onDrop = files => new Promise((resolve, reject) => {
    if(uploading) return;

    if(!files.length) {
      return;
    }

    setUploading(true);
    handleFileDrop(resolve, reject)(files);
  });

  const handleFileDrop = (resolve, reject) => async files => {
    const { onFolderDrop } = props;

    try {
      await onFolderDrop({ files, dragAndDrop:true });
      resolve();
    } catch(err) {
      reject(err);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <DropZone onDrop={onDrop} noClick>
        {({ getRootProps, getInputProps, isDragActive }) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <div {...getRootProps()} style={{position:'relative'}}>
            <input {...getInputProps()}/>
            {/* eslint-enable react/jsx-props-no-spreading */}
            <div style={{ opacity:(isDragActive || uploading) ? 0.3 : 1}}>{props.children}</div>
            {isDragActive  && <PromptOverlay/>}
            {uploading && <ProgressOverlay/>}
          </div>
        )}
      </DropZone>
    </>
  );
};

const OVERLAY_BASE = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  transition: '.5s ease',
};

function PromptOverlay() {
  const style = {
    ...OVERLAY_BASE,
    color: '#999',
    fontSize: 36,
    border: '#999 dashed 5px',
  };
  return <div style={style}>Drop folder/files here</div>;
}

function ProgressOverlay() {
  return <div style={OVERLAY_BASE}><CircularProgress/></div>;
}

export const FileInputFab = ({ allowSingeFiles=false, onChange, disabled }) => {
  const ref = useRef();

  let handleChange;
  const handleClick = () => new Promise((resolve, reject) => {
    function cancelled() {
      if(!ref.current?.files.length) resolve();
      window.removeEventListener('focus', cancelled);
    }
    window.addEventListener('focus', cancelled);
    handleChange = async ({ target }) => {
      try {
        await onChange({ files:target.files });
        resolve();
      } catch(err) {
        reject(err);
      }
    };
    ref.current?.click();
  });

  const accept = ['application/json', 'audio/*'];
  const directoryProps = allowSingeFiles ? { accept } : { directory:'', webkitdirectory:'' };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input ref={ref} type="file" onChange={e => handleChange(e)} hidden {...directoryProps} multiple/>
      <Fab disabled={disabled} onClick={handleClick} color="primary">
        <AddIcon />
      </Fab>
    </>
  );
};
