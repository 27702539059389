import bourne from '@hapi/bourne';

const BASE_REQUEST_OPTS = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export default class BackendRequest {
  constructor(opts={}) {
    const { isBlob, ...rest } = opts;
    this.requestOpts = {
      ...BASE_REQUEST_OPTS,
      ...rest,
    };
    this.isBlob = isBlob;
  }

  get = (path, queryParams) => {
    const pathWithParams = path + (!queryParams ? '' :
        `?${
            Object.keys(queryParams)
                .map(k => `${k}=${encodeURIComponent(queryParams[k])}`)
                .join('&')
        }`);

    return this.apiFetch(pathWithParams);
  };

  post = (path, body) => this.apiFetch(path, { method:'POST', body:JSON.stringify(body) });

  async apiFetch(path, opts={}) {
    const __path = path.startsWith('/') ? path : `/${path}`;
    const res = await fetch(__path, { ...this.requestOpts, ...opts });

    if(res.status === 403 && path !== 'login') {
      localStorage.clear();
      window.location = '/login';
      return;
    }

    if(this.isBlob) {
      return res;
    }

    const bodyText = await res.text();

    try {
      const body = bourne.parse(bodyText);
      if(res.status >= 300) {
        const message = body.error || JSON.stringify(body);
        throw responseError(new Error(message), res, body);
      }
      return body;
    } catch(err) {
      throw responseError(err, res, bodyText);
    }
  }
}

function responseError(err, { status }, body) {
  err.status = status;
  err.body = body;
  return err;
}
