import { createContext, useEffect, useState } from 'react';
import * as bourne from '@hapi/bourne';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

function AuthProvider(props) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const login = usr => {
    setLoading(true);
    localStorage.setItem('user', JSON.stringify(usr));
    setUser(usr);
    setLoading(false);
    navigate('/');
  };

  const logout = () => {
    setLoading(true);
    localStorage.clear();
    setUser(null);
    setLoading(false);
    navigate('/login');
  };

  const loadUser = async () => {
    setLoading(true);
    const storedUser = await localStorage.getItem('user');
    setUser(bourne.parse(storedUser));
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, login, logout, loadUser, loading }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthConsumer, AuthContext, AuthProvider };
